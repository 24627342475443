/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as e } from "../../../chunks/tslib.es6.js";
import "../../../geometry.js";
import { JSONSupport as r } from "../../../core/JSONSupport.js";
import { property as o } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/has.js";
import "../../../core/Logger.js";
import "../../../core/RandomLCG.js";
import { enumeration as t } from "../../../core/accessorSupport/decorators/enumeration.js";
import { subclass as p } from "../../../core/accessorSupport/decorators/subclass.js";
import s from "../../../geometry/SpatialReference.js";
import { featureGeometryTypeKebabDictionary as i } from "../../../geometry/support/typeUtils.js";
var c;
let a = c = class extends r {
  constructor(e) {
    super(e), this.type = "query-table";
  }
  clone() {
    const {
        workspaceId: e,
        query: r,
        oidFields: o,
        spatialReference: t,
        geometryType: p
      } = this,
      s = {
        workspaceId: e,
        query: r,
        oidFields: o,
        spatialReference: t?.clone() ?? void 0,
        geometryType: p
      };
    return new c(s);
  }
};
e([t({
  queryTable: "query-table"
})], a.prototype, "type", void 0), e([o({
  type: String,
  json: {
    write: !0
  }
})], a.prototype, "workspaceId", void 0), e([o({
  type: String,
  json: {
    write: !0
  }
})], a.prototype, "query", void 0), e([o({
  type: String,
  json: {
    write: !0
  }
})], a.prototype, "oidFields", void 0), e([o({
  type: s,
  json: {
    write: !0
  }
})], a.prototype, "spatialReference", void 0), e([t(i)], a.prototype, "geometryType", void 0), a = c = e([p("esri.layers.support.source.QueryTableDataSource")], a);
export { a as QueryTableDataSource };