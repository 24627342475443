/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as o } from "../../../chunks/tslib.es6.js";
import { JSONSupport as r } from "../../../core/JSONSupport.js";
import { property as e } from "../../../core/accessorSupport/decorators/property.js";
import "../../../core/has.js";
import "../../../core/Logger.js";
import "../../../core/RandomLCG.js";
import { enumeration as t } from "../../../core/accessorSupport/decorators/enumeration.js";
import { subclass as s } from "../../../core/accessorSupport/decorators/subclass.js";
var p;
let a = p = class extends r {
  constructor(o) {
    super(o), this.type = "table";
  }
  clone() {
    const {
      workspaceId: o,
      gdbVersion: r,
      dataSourceName: e
    } = this;
    return new p({
      workspaceId: o,
      gdbVersion: r,
      dataSourceName: e
    });
  }
};
o([t({
  table: "table"
})], a.prototype, "type", void 0), o([e({
  type: String,
  json: {
    write: !0
  }
})], a.prototype, "workspaceId", void 0), o([e({
  type: String,
  json: {
    write: !0
  }
})], a.prototype, "gdbVersion", void 0), o([e({
  type: String,
  json: {
    write: !0
  }
})], a.prototype, "dataSourceName", void 0), a = p = o([s("esri.layers.support.source.TableDataSource")], a);
export { a as TableDataSource };