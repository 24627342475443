/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { _ as r } from "../../../chunks/tslib.es6.js";
import { JSONSupport as o } from "../../../core/JSONSupport.js";
import { property as e } from "../../../core/accessorSupport/decorators/property.js";
import { Integer as s } from "../../../core/accessorSupport/ensureType.js";
import "../../../core/RandomLCG.js";
import "../../../core/has.js";
import { enumeration as p } from "../../../core/accessorSupport/decorators/enumeration.js";
import { subclass as t } from "../../../core/accessorSupport/decorators/subclass.js";
var a;
let c = a = class extends o {
  constructor(r) {
    super(r), this.type = "map-layer";
  }
  clone() {
    const {
      mapLayerId: r,
      gdbVersion: o
    } = this;
    return new a({
      mapLayerId: r,
      gdbVersion: o
    });
  }
};
r([p({
  mapLayer: "map-layer"
})], c.prototype, "type", void 0), r([e({
  type: s,
  json: {
    write: !0
  }
})], c.prototype, "mapLayerId", void 0), r([e({
  type: String,
  json: {
    write: !0
  }
})], c.prototype, "gdbVersion", void 0), c = a = r([t("esri.layers.support.source.MapLayerSource")], c);
export { c as MapLayerSource };